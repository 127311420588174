
import "../style/SubscriptionPlans.css";
import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../config/config";
function Paymentdetails() {
  const [currentPage, setCurrentPage] = useState(1);
  const emailsPerPage = 10;
  const [paymentHistory, setPaymentHistory] = useState([]);


  useEffect(() => {
    axios
      .get(`${config.apiBaseUrl}/admin/Paymenttransaction`) 
      .then((response) => {
        setPaymentHistory(response.data);
      })
      .catch((error) => {
        console.log("Failed to fetch payment history");
      });
  }, []);



  const indexOfLastEmail = currentPage * emailsPerPage;
  const indexOfFirstEmail = indexOfLastEmail - emailsPerPage;
  const currentEmails = paymentHistory.slice(indexOfFirstEmail, indexOfLastEmail);

  const totalPages = Math.ceil(paymentHistory.length / emailsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className='Teams'>
      <h6 className='inboxhead'>Inbox</h6>
      <div className="email-list  mt-1 table-responsive1">
        <div className='row'>
          <div className='col-12'>
            <span>Page {currentPage} of {totalPages}</span>
            <span
              className="material-symbols-outlined text-center arrorleft-icon"
              onClick={handlePreviousPage}
            >
              arrow_back_ios
            </span>
            <span
              onClick={handleNextPage}
              className={`material-symbols-outlined arroright-icon ${currentPage === totalPages ? 'disabled' : ''}`}
            >
              arrow_forward_ios
            </span>
          </div>
        </div>
        <table className='table mt-3'>
          <thead className='text-center'>
            <tr className='email-item1'>
            <th>USERID</th>
              <th>NAME</th>
              <th>PHONE NO</th>
              <th>AMOUNT</th>
              <th>PLANTYPE</th>
              <th>STATUS</th>
              <th>DATE</th>
            </tr>
          </thead>
          <tbody className='text-center'>
            {currentEmails.map((email, index) => (
              <tr key={index} className="email-item">
                <td >{email.user_id}</td>
                <td >{email.username}</td>
                <td>{email.mobile_no}</td>
                <td>{email.amount_in_paise /100}</td>
                <td>{email.features}</td>
                <td ><p className='status'>{email.transaction_status}</p></td>
                <td>{email.created_at}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Paymentdetails;
