import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import './forget.css';
import logo from "../../assets/framlogo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../config/config";

function Emailotp() {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false); 
  const navigate = useNavigate();
  const handlesend = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await axios.post(`${config.apiBaseUrl}/admin/sentotpmailadmin`, {email});
      toast.success("OTP Send successful!");
      setTimeout(() => {
        navigate("/Setpassword");
      }, 1000);
    } catch (error) {
      console.error('Error:', error);
      if (error.response) {
        toast.error("Check correct email. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container-fluid login-body">
      <div className="login-container">
        <div className="login-box">
          <img src={logo} alt="Kovai Caterers" className="logos" />
          <form onSubmit={handlesend}>
            <div>
              <h6 className="reglable">Enter your E-mail</h6>
              <input
                type="email"
                className="reg-form  custom-inputs"
                value={email}
                placeholder="Enter your email"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="login-button mt-4">
              {isLoading ? "Sending..." : "Send OTP"}
            </button>
          </form>
          <h6 className="backlogin mt-4">Back to log in<a href="/"  className=" logintext-link">Login</a></h6>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Emailotp;
