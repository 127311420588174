import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "../src/component/pages/Dashboard";
import Sidebar from "../src/component/sidebar/sidebar";
import Login from "../src/component/login/login";
import Emailotp from './component/forgetpassword/EmailOtp';
import Setpassword from './component/forgetpassword/Setpassword';
import PrivateRoute from '../src/component/privaterout'; 
import SubscriptionPlans from './component/pages/Subcribition';
import Userlist from './component/pages/userlist';
import Case from './component/pages/Userreport';

function MainLayout() {
  return (
    <div className="main__layout">
   <Sidebar />
      <div className="content">
        <Routes>
          <Route path="/dashboard" element={<PrivateRoute element={Dashboard}/>} />
          <Route path="/Subscribtion" element={<PrivateRoute element={SubscriptionPlans}/>} />
          <Route path="/user-report" element={<PrivateRoute element={Case}/>} />
          <Route path="/user-list" element={<PrivateRoute element={Userlist}/>} />
        </Routes>
      </div>
      
    </div>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="*" element={<MainLayout />} />
        <Route path="/Sendmailotp" element={<Emailotp />} />
        <Route path="/Setpassword" element={<Setpassword />} />
        <Route path="/login" element={<Login />} /> 
      </Routes>
    </BrowserRouter>
  );
}

export default App;
