import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../style/userlist.css";
import axios from 'axios';
import config from "../config/config";

const Userlist = () => {
    const [users, setUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const PerPage = 10;

    const indexOfLastUser = currentPage * PerPage;
    const indexOfFirstUser = indexOfLastUser - PerPage;
    const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

    const totalPages = Math.ceil(users.length / PerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    useEffect(() => {
        axios.get(`${config.apiBaseUrl}/admin/userlist`)
            .then(response => {
                setUsers(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the user list!', error);
            });
    }, []);

    return (
        <div className="drafthistory">
            <div className="tablbody1">
                <h1 className="usertitle">User List</h1>
                <table className='table'>
                    <thead>
                        <tr>
                            <th className='text-center'>USERID</th>
                            <th>Username</th>
                            <th>Mobile Number</th>
                            <th>Email</th>
                            <th>TIME</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentUsers.map((user, index) => (
                            <tr key={user.id}>
                                <td className='text-center'>{user.id}</td>
                                <td>{user.username}</td>
                                <td>{user.mobile_no}</td>
                                <td>{user.email}</td>
                                <td>{user.created_at}</td>
                            </tr>
                        ))}

                    </tbody>
                </table>
                <div className='row mt-2 col-12 '>
                    <div className='d-flex justify-content-end'>
                        <span>Page {currentPage} of {totalPages}</span>
                        <span
                            className="material-symbols-outlined text-center text-dark mt-2 arrorleft-icon"
                            onClick={handlePreviousPage}
                        >
                            arrow_back_ios
                        </span>
                        <span
                            onClick={handleNextPage}
                            className={`material-symbols-outlined arroright-icon mt-2 ${currentPage === totalPages ? 'disabled' : ''}`}
                        >
                            arrow_forward_ios
                        </span>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Userlist;
