import "../style/dashboard.css";
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIndianRupee } from "@fortawesome/free-solid-svg-icons";
import Total from "../../assets/Icon.png";
import NeW from "../../assets/Icon2.png";
import cicle from "../../assets/Icon4.png";
import circle2 from "../../assets/icon3.png";
import axios from "axios";
import config from "../config/config";
import CountUp from 'react-countup';
import { toast } from "react-toastify";
import Useractive from '../../assets/Frame 44.svg';
import Userinactive from '../../assets/Frame 45(1).svg';
import Scenario from '../../assets/Coins.svg';
import LegalDrafting from '../../assets/Coins1.svg';
import CrossQA from '../../assets/Coins2.svg';
import Relavent from '../../assets/Coins3.svg';
import { Chart } from "react-google-charts";

const Dashboard = () => {
  const [userCount, setUserCount] = useState(0);
  const [newuserCount, setnewuserCount] = useState(0);
  const [userCounts, setUserCounts] = useState({ activeUsers: 0, inactiveUsers: 0 });
  const [monthlyData, setMonthlyData] = useState([["Month", "Sales"]]);

  const [activeUserCount, setActiveUserCount] = useState(0);
  const [leactiveUserCount, setLeActiveUserCount] = useState(0);
  const [cactiveUserCount, setCActiveUserCount] = useState(0);
  const [ractiveUserCount, setrActiveUserCount] = useState(0);


  const [totalAmount, setTotalAmount] = useState(0);
  const [monthlyRevenue, setMonthlyRevenue] = useState(0);


  const fetchTotalAmount = async () => {
    try {
      const response = await axios.get(`${config.apiBaseUrl}/admin/total-amount`);
      setTotalAmount(response.data.total_amount);
    } catch (err) {
      console.log(err.message);
    }
  };

  const fetchMonthlyRevenue = async () => {
    try {
      const response = await axios.get(`${config.apiBaseUrl}/admin/monthly-revenue`);
      setMonthlyRevenue(response.data.monthly_revenue);
    } catch (err) {
      console.log(err.message);
    }
  };
  useEffect(() => {
    fetchTotalAmount();
    fetchMonthlyRevenue();
  }, []);


  const fetchActiveUsers = async (endpoint, setter) => {
    try {
      const response = await fetch(`${config.apiBaseUrl}${endpoint}`);
      const data = await response.json();
      if (response.ok) {
        setter(data);
      } else {
        console.log(`Failed to fetch active users from ${endpoint}`);
      }
    } catch (error) {
      console.log('An error occurred while fetching active users', error);
    }
  };
  useEffect(() => {

    fetchActiveUsers('/s_active-users', (data) => setActiveUserCount(data.activeUserCount || 0));
    fetchActiveUsers('/L_active-users', (data) => setLeActiveUserCount(data.LeagalactiveCount || 0));
    fetchActiveUsers('/C_active-users', (data) => setCActiveUserCount(data.crossactiveCount || 0));
    fetchActiveUsers('/R_active-users', (data) => setrActiveUserCount(data.ReleventactiveCount || 0));
  }, []);

  useEffect(() => {
    const fetchMonthlyData = async () => {
      try {
        const response = await fetch(`${config.apiBaseUrl}/admin/monthlypichart`);
        const result = await response.json();
        if (result.success) {
          // Transforming the data into the format required by Google Charts
          const chartData = [["Month", "Sales"]]; // Chart header
          result.data.forEach(item => {
            chartData.push([item.Month, parseFloat(item.Sales)]); // Parsing Sales to number
          });
          setMonthlyData(chartData);
        }
      } catch (error) {
        console.error("Error fetching monthly sales data:", error);
      }
    };
    fetchMonthlyData();
  }, []);

  const options = {
    title: "Revenue Distribution",
    pieHole: 0.2,
    is3D: false,
    tooltip: {
      trigger: "selection",
      textStyle: {
        color: "blue",
        fontSize: 14,
      },
      isHtml: true,
    },
  };




  useEffect(() => {
    const fetchUserCount = async () => {
      try {
        const response = await axios.get(`${config.apiBaseUrl}/admin/users`);
        setUserCount(response.data.userCount);
      } catch (err) {
        console.error("Error fetching user count:", err);
      }
    };
    fetchUserCount();
  }, []);

  useEffect(() => {
    const fetchUserCount = async () => {
      try {
        const response = await axios.get(`${config.apiBaseUrl}/admin/newusers`);
        setnewuserCount(response.data.userCount);
      } catch (err) {
        console.error("Error fetching user data:", err);
      }
    };
    fetchUserCount();
  }, []);





  useEffect(() => {
    const fetchUserStatus = async () => {
      try {
        const response = await axios.get(`${config.apiBaseUrl}/admin/userstatus`);
        setUserCounts(response.data);

      } catch (err) {
        toast.error("Failed to fetch user status");
      }
    };
    fetchUserStatus();
  }, []);


  return (
    <div className="dashboard">
      <h5 className="dashstart">Dashboard</h5>
      <div className="row p-2" >
        {/* Total User Card */}
        <div className="col-md-6 col-lg-3 col-sm-6 col-12 mt-3">
          <div className="card card-boc1">
            <div className="row">
              <div className="col-9">
                <h3 className="card-head p-3 ellipsis ">Total User</h3>
              </div>
              <div className="col-3">
                <img src={Total} className="mt-3 wcards" alt="Total User Icon" />
              </div>
              <h5 className="count-dash">
                <CountUp end={userCount} duration={5} />
              </h5>
            </div>
          </div>
        </div>

        {/* New User Card */}
        <div className="col-md-6 col-lg-3 col-sm-6 col-12 mt-3">
          <div className="card card-boc1">
            <div className="row">
              <div className="col-9">
                <h3 className="card-head p-3 ellipsis ">New User</h3>
              </div>
              <div className="col-3">
                <img src={NeW} className="mt-3 wcards" alt="New User Icon" />
              </div>
              <h5 className="count-dash">
                <CountUp end={newuserCount} duration={3} />
              </h5>
            </div>
          </div>
        </div>

        {/* Total Revenue Card */}
        <div className="col-md-6 col-lg-3 col-sm-6 col-12 mt-3">
          <div className="card card-boc1">
            <div className="row">
              <div className="col-9">
                <h3 className="card-head p-3 ellipsis ">Total Revenue</h3>
              </div>
              <div className="col-3">
                <img src={cicle} className="mt-3 wcards" alt="Total Revenue Icon" />
              </div>
              <h6 className="count-dash">
                <FontAwesomeIcon icon={faIndianRupee} />{' '}
                <CountUp end={totalAmount} duration={5} />
              </h6>
            </div>
          </div>
        </div>

        {/* Monthly Revenue Card */}
        <div className="col-md-6 col-lg-3 col-sm-6 col-12 mt-3">
          <div className="card card-boc1">
            <div className="row">
              <div className="col-9">
                <h3 className="card-head p-3 ellipsis ">Monthly Revenue</h3>
              </div>
              <div className="col-3">
                <img src={circle2} className="mt-3 wcards" alt="Monthly Revenue Icon" />
              </div>
              <h5 className="count-dash">
                <FontAwesomeIcon icon={faIndianRupee} />{' '}
                <CountUp end={monthlyRevenue} duration={5} />
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div className="row pt-2 p-2 ">
        <div className="col-lg-6 m3-4 col-12 order-sm-2 order-md-1 order-lg-1  order-2 p-2 mb-0 ">
          <div className=" graphline">
            <h5 className="graphead ">Revenue Graph</h5>
            <div className="pt-2">
              <Chart
                chartType="PieChart"
                width="100%"
                height="400px"
                data={monthlyData}
                options={options}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-6  order-sm-1 order-md-2 order-lg-2 order-1 pt-2 p-3 col-12 ">
          <div className="col-12 graphline">
            <h5 className="graphead mb-0">User Indecate</h5>
            <div className="row p-3">
              <div className="col-md-6 col-lg-6 col-sm-6 col-12 mt-3">
                <div className="card card-active  ">
                  <div className="row">
                    <div className="col-lg-4  col-md-6 col-4 mt-2">
                      <img src={Useractive} className="p-0" alt="vv" />
                    </div>
                    <div className="col-8 col-md-6 col-8 mt-3 p-0">
                      <h3 className="card-head mb-1  ellipsis ">User active </h3>
                      <h6 className="mb-0">  {userCounts.activeUsers}</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-sm-6 col-12 mt-3">
                <div className="card card-inactive  ">
                  <div className="row">
                    <div className="col-4 mt-2">
                      <img src={Userinactive} className="p-0" alt="vv" />
                    </div>
                    <div className="col-8 mt-3 p-0">
                      <h3 className="card-head mb-1  ellipsis ">User Inactive </h3>
                      <h6 className="mb-0">   {userCounts.inactiveUsers}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mt-3 p-4 graphline">
            <h5 className="graphead">User usage</h5>
            <div className="row  ">
              <div className="col-md-6 col-lg-6 col-sm-6 col-12 mt-3">
                <div className="card card-scerio  ">
                  <div className="row">
                    <div className="col-12 p-3">
                      <img src={LegalDrafting} className="p-0" alt="vv" />
                    </div>
                    <div className="col-12 mt-1 ">
                      <h3 className="card-head mb-1  ellipsis ">Scenario solver </h3>
                      <h4 className="mb-0"> {activeUserCount}</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-sm-6 col-12 mt-3">
                <div className="card card-legal">
                  <div className="row">
                    <div className="col-12 p-3">
                      <img src={CrossQA} className="p-0" alt="vv" />
                    </div>
                    <div className="col-12 mt-1 ">
                      <h3 className="card-head mb-1  ellipsis ">Legal Drafting </h3>
                      <h5 className="mb-0">   {leactiveUserCount}</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-sm-6 col-12 mt-3">
                <div className="card card-cross">
                  <div className="row">
                    <div className="col-12 p-3">
                      <img src={Relavent} className="p-0" alt="vv" />
                    </div>
                    <div className="col-12 mt-1 ">
                      <h3 className="card-head mb-1  ellipsis ">Cross Q/A </h3>
                      <h5 className="mb-0"> {cactiveUserCount}</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-sm-6 col-12 mt-3">
                <div className="card card-judgement">
                  <div className="row">
                    <div className="col-12 p-3">
                      <img src={Scenario} className="p-0" alt="vv" />
                    </div>
                    <div className="col-12 mt-1">
                      <h3 className="card-head mb-1   ">Relavent  judgement </h3>
                      <h6 className="mb-0">   {ractiveUserCount}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  );
};

export default Dashboard;
